import { AlertDialog, AlertDialogRoot, AlertDialogTrigger } from 'components/common/Alert';
import { Button } from 'components/common/Button';
import { Form, TextArea } from 'components/common/Form';
import { FileUploader } from 'components/common/Form/FileUploader';
import { Icon } from 'components/common/Icon';
import { PageLoader } from 'components/common/Loader';
import { Body1, Body3, Date } from 'components/common/Typography';
import { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { useMessageController } from './contoller.post';
import { useMessageSender } from './controller.sender';
import { useThreadController2 } from './controller.thread';
import { Box, Content, FileUpload, Header, IconBox, Message, NewMessageForm, Post } from './styles';

export const Thread = ({ lineId, customId }: { lineId: string; customId: string }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const thread = useThreadController2(lineId);
  const posts = useMessageController(lineId, contentRef);
  const sender = useMessageSender(lineId, contentRef);

  return (
    <Box>
      {thread.isPending ? (
        <PageLoader />
      ) : (
        <Header>
          <Body1 weight='bold'>{customId}</Body1>
          {!thread.data.includeDsr && !thread.isNewLinetalk && (
            <AlertDialogRoot>
              <AlertDialogTrigger asChild>
                <IconBox title='Invite DSR staff'>
                  <Icon variant='userPlus' />
                </IconBox>
              </AlertDialogTrigger>
              <AlertDialog
                title='Invite DSR staff'
                message='Are you sure to invite DSR staff?'
                width={450}
                onAction={thread.onRequestInviteDSRStaff}
                actionLabel='Invite'
              />
            </AlertDialogRoot>
          )}
        </Header>
      )}

      <Content id='linetalk-posts' ref={contentRef}>
        <div style={{ height: '100%', overflow: 'auto' }}>
          {posts.isLoading && posts.initialLoading ? (
            <PageLoader />
          ) : !!thread.isNewLinetalk ? (
            <Body3 weight='regular' style={{ marginTop: 64, textAlign: 'center', display: 'block' }}>
              Linetalk hasn't started yet.
              <br />
              Try sending your first message.
            </Body3>
          ) : (
            <>
              {posts.data.hasNext && (
                <div style={{ marginBottom: 24 }}>
                  <Button
                    variant='outlined'
                    color='gray'
                    size='small'
                    fluid
                    onClick={posts.handlers.onLoadMore}
                    disabled={posts.inLoadMore}
                  >
                    {'Load more'}
                  </Button>
                </div>
              )}
              {posts.data.list.map(post => (
                <Post key={post.id} isMine={post.isMine}>
                  {!post.isMine && (
                    <Body3 weight='medium'>
                      {post.author.firstName} {post.author.lastName}
                    </Body3>
                  )}
                  <div>
                    {post.isMine && <Date>{post.createdAt}</Date>}
                    <Message>
                      <Body3 weight='regular'>{post.message}</Body3>
                      <Body3 weight='regular'>
                        {post.attachments.map(att => (
                          <a key={att.id} role='button' href={att.url} target='_blank' rel='noreferrer'>
                            <Icon variant='attachFile' /> {att.filename}
                            <br />
                          </a>
                        ))}
                      </Body3>
                    </Message>
                    {!post.isMine && <Date>{post.createdAt}</Date>}
                  </div>
                </Post>
              ))}
            </>
          )}
        </div>
      </Content>

      <div>
        <Form onSubmit={sender.form.handleSubmit(sender.onSubmit)} style={{ marginTop: 0 }}>
          <NewMessageForm>
            {/* min-width: 0 - firefox에서 flexbox layout 문제 해결 */}
            <TextArea rows={4} style={{ flex: 1, minWidth: 0 }} {...sender.form.register('message')} />
            <Button variant='contained' color='gray' type='submit'>
              SEND
            </Button>
          </NewMessageForm>
          {!thread.isNewLinetalk &&
            (sender.attachments.isPending ? (
              <Icon variant='loading' strokeWidth={4} width={24} height={24} />
            ) : (
              <FileUpload>
                <Controller
                  control={sender.form.control}
                  name='attachments'
                  render={({ field: { onChange } }) => (
                    <FileUploader multiple onChange={onChange}>
                      File upload
                    </FileUploader>
                  )}
                />
              </FileUpload>
            ))}
        </Form>
      </div>
    </Box>
  );
};
