/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddVesselWithFleetManagerAndSubEntitiesApiV12FleetsUserIdVesselsPostData,
  AddVesselWithFleetManagerAndSubEntitiesApiV12FleetsUserIdVesselsPostError,
  AddVesselWithFleetManagerApiV1FleetsUserIdVesselsPostData,
  AddVesselWithFleetManagerApiV1FleetsUserIdVesselsPostError,
  DeleteVesselApiV12VesselsVesselIdDeleteData,
  DeleteVesselApiV12VesselsVesselIdDeleteError,
  GetVesselApiV1VesselsVesselIdGetData,
  GetVesselApiV1VesselsVesselIdGetError,
  GetVesselV11ApiV11VesselsVesselIdGetData,
  GetVesselV11ApiV11VesselsVesselIdGetError,
  GetVesselV12ApiV12VesselsVesselIdGetData,
  GetVesselV12ApiV12VesselsVesselIdGetError,
  ListVesselsApiV1VesselsGetData,
  ListVesselsApiV1VesselsGetError,
  ListVesselsApiV1VesselsGetParams,
  ListVesselsByCompanyApiV1CompaniesCompanyIdVesselsGetData,
  ListVesselsByCompanyApiV1CompaniesCompanyIdVesselsGetError,
  ListVesselsByCompanyApiV1CompaniesCompanyIdVesselsGetParams,
  ListVesselsByFleetApiV1FleetsUserIdVesselsGetData,
  ListVesselsByFleetApiV1FleetsUserIdVesselsGetError,
  ListVesselsByFleetApiV1FleetsUserIdVesselsGetParams,
  ListVesselsByUserApiV1UsersSelfVesselsGetData,
  ReorderWinchesAndDrums,
  ReorderWinchesAndDrumsApiV12VesselsVesselIdOrdersPatchData,
  ReorderWinchesAndDrumsApiV12VesselsVesselIdOrdersPatchError,
  UpdateVesselApiV1VesselsVesselIdPatchData,
  UpdateVesselApiV1VesselsVesselIdPatchError,
  UpdateVesselInfoApiV11VesselsVesselIdPatchData,
  UpdateVesselInfoApiV11VesselsVesselIdPatchError,
  UpdateVesselMemoApiV1VesselsVesselIdMemosPatchData,
  UpdateVesselMemoApiV1VesselsVesselIdMemosPatchError,
  VesselCreateWithCrew,
  VesselCreateWithCrewAndSubEntities,
  VesselMemoUpdate,
  VesselResetAllHistoryApiV12VesselsVesselIdResetAllHistoryPostData,
  VesselResetAllHistoryApiV12VesselsVesselIdResetAllHistoryPostError,
  VesselResetAllHistoryApiV12VesselsVesselIdResetAllHistoryPostParams,
  VesselUpdate,
  VesselUpdateV2,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Vessel<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
undefined
 *
undefined
 */
  listVesselsByUserApiV1UsersSelfVesselsGet = (params: RequestParams = {}) =>
    this.http.request<ListVesselsByUserApiV1UsersSelfVesselsGetData, any>({
      path: `/api/v1/users/self/vessels/`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  listVesselsApiV1VesselsGet = (query: ListVesselsApiV1VesselsGetParams, params: RequestParams = {}) =>
    this.http.request<ListVesselsApiV1VesselsGetData, ListVesselsApiV1VesselsGetError>({
      path: `/api/v1/vessels/`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  listVesselsByCompanyApiV1CompaniesCompanyIdVesselsGet = (
    { companyId, ...query }: ListVesselsByCompanyApiV1CompaniesCompanyIdVesselsGetParams,
    params: RequestParams = {}
  ) =>
    this.http.request<
      ListVesselsByCompanyApiV1CompaniesCompanyIdVesselsGetData,
      ListVesselsByCompanyApiV1CompaniesCompanyIdVesselsGetError
    >({
      path: `/api/v1/companies/${companyId}/vessels/`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  listVesselsByFleetApiV1FleetsUserIdVesselsGet = (
    { userId, ...query }: ListVesselsByFleetApiV1FleetsUserIdVesselsGetParams,
    params: RequestParams = {}
  ) =>
    this.http.request<
      ListVesselsByFleetApiV1FleetsUserIdVesselsGetData,
      ListVesselsByFleetApiV1FleetsUserIdVesselsGetError
    >({
      path: `/api/v1/fleets/${userId}/vessels/`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  addVesselWithFleetManagerApiV1FleetsUserIdVesselsPost = (
    userId: string,
    data: VesselCreateWithCrew,
    params: RequestParams = {}
  ) =>
    this.http.request<
      AddVesselWithFleetManagerApiV1FleetsUserIdVesselsPostData,
      AddVesselWithFleetManagerApiV1FleetsUserIdVesselsPostError
    >({
      path: `/api/v1/fleets/${userId}/vessels/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  getVesselApiV1VesselsVesselIdGet = (vesselId: string, params: RequestParams = {}) =>
    this.http.request<GetVesselApiV1VesselsVesselIdGetData, GetVesselApiV1VesselsVesselIdGetError>({
      path: `/api/v1/vessels/${vesselId}/`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  updateVesselApiV1VesselsVesselIdPatch = (vesselId: string, data: VesselUpdate, params: RequestParams = {}) =>
    this.http.request<UpdateVesselApiV1VesselsVesselIdPatchData, UpdateVesselApiV1VesselsVesselIdPatchError>({
      path: `/api/v1/vessels/${vesselId}/`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  updateVesselMemoApiV1VesselsVesselIdMemosPatch = (
    vesselId: string,
    data: VesselMemoUpdate,
    params: RequestParams = {}
  ) =>
    this.http.request<
      UpdateVesselMemoApiV1VesselsVesselIdMemosPatchData,
      UpdateVesselMemoApiV1VesselsVesselIdMemosPatchError
    >({
      path: `/api/v1/vessels/${vesselId}/memos/`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  getVesselV11ApiV11VesselsVesselIdGet = (vesselId: string, params: RequestParams = {}) =>
    this.http.request<GetVesselV11ApiV11VesselsVesselIdGetData, GetVesselV11ApiV11VesselsVesselIdGetError>({
      path: `/api/v1.1/vessels/${vesselId}/`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  updateVesselInfoApiV11VesselsVesselIdPatch = (vesselId: string, data: VesselUpdateV2, params: RequestParams = {}) =>
    this.http.request<UpdateVesselInfoApiV11VesselsVesselIdPatchData, UpdateVesselInfoApiV11VesselsVesselIdPatchError>({
      path: `/api/v1.1/vessels/${vesselId}/`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  getVesselV12ApiV12VesselsVesselIdGet = (vesselId: string, params: RequestParams = {}) =>
    this.http.request<GetVesselV12ApiV12VesselsVesselIdGetData, GetVesselV12ApiV12VesselsVesselIdGetError>({
      path: `/api/v1.2/vessels/${vesselId}/`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  deleteVesselApiV12VesselsVesselIdDelete = (vesselId: string, params: RequestParams = {}) =>
    this.http.request<DeleteVesselApiV12VesselsVesselIdDeleteData, DeleteVesselApiV12VesselsVesselIdDeleteError>({
      path: `/api/v1.2/vessels/${vesselId}/`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  addVesselWithFleetManagerAndSubEntitiesApiV12FleetsUserIdVesselsPost = (
    userId: string,
    data: VesselCreateWithCrewAndSubEntities,
    params: RequestParams = {}
  ) =>
    this.http.request<
      AddVesselWithFleetManagerAndSubEntitiesApiV12FleetsUserIdVesselsPostData,
      AddVesselWithFleetManagerAndSubEntitiesApiV12FleetsUserIdVesselsPostError
    >({
      path: `/api/v1.2/fleets/${userId}/vessels/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  reorderWinchesAndDrumsApiV12VesselsVesselIdOrdersPatch = (
    vesselId: string,
    data: ReorderWinchesAndDrums,
    params: RequestParams = {}
  ) =>
    this.http.request<
      ReorderWinchesAndDrumsApiV12VesselsVesselIdOrdersPatchData,
      ReorderWinchesAndDrumsApiV12VesselsVesselIdOrdersPatchError
    >({
      path: `/api/v1.2/vessels/${vesselId}/orders/`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  vesselResetAllHistoryApiV12VesselsVesselIdResetAllHistoryPost = (
    { vesselId, ...query }: VesselResetAllHistoryApiV12VesselsVesselIdResetAllHistoryPostParams,
    params: RequestParams = {}
  ) =>
    this.http.request<
      VesselResetAllHistoryApiV12VesselsVesselIdResetAllHistoryPostData,
      VesselResetAllHistoryApiV12VesselsVesselIdResetAllHistoryPostError
    >({
      path: `/api/v1.2/vessels/${vesselId}/reset_all_history/`,
      method: 'POST',
      query: query,
      secure: true,
      ...params,
    });
}
