import HttpStatusCode from 'lib/ajax/statusCodes';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  useGetLinetalkThreadApiV1LinetalksThreadIdQuery,
  useInviteDsrStaffToThreadApiV1LinetalksThreadIdInvitesMutation,
} from 'services/linetalk/queries';
import { useLinetalkThreadInfo } from './atoms';


// NOTE: Actually, LineID and ThreadId are same.
export const useThreadController2 = (threadId: string) => {
  const [{ isNewLinetalk }, setLinetalkThreadInfo] = useLinetalkThreadInfo();

  const detailApi = useGetLinetalkThreadApiV1LinetalksThreadIdQuery(threadId);
  const inviteDSRStaff = useInviteDsrStaffToThreadApiV1LinetalksThreadIdInvitesMutation(threadId);

  const threadData = detailApi.data || { id: '', includeDsr: false };

  useEffect(() => {
    if (detailApi.status === 'success') {
      setLinetalkThreadInfo(prev => ({ ...prev, isNewLinetalk: false }));
    } else {
      setLinetalkThreadInfo(prev => ({ ...prev, isNewLinetalk: true }));
    }
  }, [detailApi.status, setLinetalkThreadInfo]);

  const onRequestInviteDSRStaff = async () => {
    const res = await inviteDSRStaff.mutateAsync(threadId);
    if (res.status === HttpStatusCode.ACCEPTED) {
      toast.success('DSR staff has been invited.', {
        duration: 3000,
        position: 'bottom-center'
      });
    } else {
      toast.error('The invitation failed. Please try again in a moment.', {
        duration: 3000,
        position: 'bottom-center'
      });
    }
  };

  const [showInviteDSRStaffConfirmDialog, setShowInviteDSRStaffConfirmDialog] = useState(false);

  return {
    ...detailApi,
    data: threadData,
    isNewLinetalk,
    confirm: {
      showInviteDSRStaffConfirmDialog,
      setShowInviteDSRStaffConfirmDialog,
    },
    onRequestInviteDSRStaff,
  };
};

