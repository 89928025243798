import { parseUTCDateTime } from 'lib/utils';
import { DateTime, UUID, WinchDirections } from 'services/schema';
import { VesselReadDetailV2 } from 'swagger/data-contracts';

export type Vessel = {
  id: UUID;
  hin: string;
  name: string;
  abbreviation: string;
  typeName: string;
  memo?: string;
  winches: Winch[];
  moorings: Mooring[];
  activeMooring: (Mooring & { drums: Drum[] }) | null;
};
export type Winch = {
  id: UUID;
  name: string;
  positionX: number;
  positionY: number;
  drumCount: number;
  angle: WinchDirections;
  drums: Drum[];
};

type Installation = {
  created_at: DateTime;
  drum_id: UUID;
  id: UUID;
  installation_type: number;
  installed_time: DateTime;
  line_id: UUID;
  uninstalled_time: DateTime | null;
  updated_at: DateTime;
}
export type Drum = {
  id: UUID;
  name: string;
  position: number;
  hasTailLine: boolean;
  installedLines: {
    customId: string | null;
    id: string;
    isRetired: boolean;
    workingHours: number;
    workingHoursLimit: number;
    certificateIssuedDate: DateTime;
    createdAt: DateTime;
    diameter: string;
    length: string;
    lineId: string;
    mbl: string;
    meg4Id: string;
    strand: string;
    suppliedDate: DateTime;
  }[];
  installations: {
    main: Installation | null;
    tail: Installation | null;
  };
  moored?: boolean;
  _winchInfo?: {
    id: string;
    name: string;
    moored: boolean;
  };
};

export type Mooring = {
  id: UUID;
  portCode: string;
  startedTime: DateTime;
  endedTime: DateTime | null;
  duration: number;
};

export const parseVessel = (raw: VesselReadDetailV2): Vessel => ({
  id: raw.id,
  hin: raw.hin,
  name: raw.name,
  abbreviation: raw.abbreviation || '',
  typeName: raw.type_name,
  memo: raw.memo,
  winches:
    raw.winches.map(w => ({
      id: w.id,
      name: w.name,
      positionX: w.position_x,
      positionY: w.position_y,
      drumCount: w.drum_count,
      angle: (w.angle + '') as WinchDirections,
      drums: w.drums.map(d => ({
        id: d.id,
        name: d.name,
        position: d.position,
        hasTailLine: d.has_tail_line,
        installedLines: d.installed_lines.map(l => ({
          customId: l.custom_id || null,
          id: l.id,
          isRetired: l.is_retired,
          workingHours: l.working_hours,
          workingHoursLimit: l.working_hours_limit,
          certificateIssuedDate: l.certificate_issued_date,
          createdAt: l.created_at,
          diameter: l.diameter,
          length: l.length,
          lineId: l.line_id,
          mbl: l.mbl,
          meg4Id: l.meg4_id,
          strand: l.strand,
          suppliedDate: l.supplied_date,
        })),
        installations: {
          //@ts-ignore
          main: d.installations.main,
          //@ts-ignore
          tail: d.installations.tail,
        },
      })),
    })) || [],
  moorings: raw.moorings.map(m => ({
    id: m.id,
    portCode: m.port_code,
    startedTime: parseUTCDateTime(m.started_time),
    endedTime: parseUTCDateTime(m.ended_time || null),
    duration: m.duration,
  })),
  activeMooring: raw.active_mooring
    ? {
      id: raw.active_mooring.id,
      portCode: raw.active_mooring.port_code,
      startedTime: parseUTCDateTime(raw.active_mooring.started_time),
      endedTime: parseUTCDateTime(raw.active_mooring.ended_time || null),
      duration: raw.active_mooring.duration,
      drums: raw.active_mooring.drums.map(d => {
        // TODO: Mooring쪽 UI 로직 좀 더 쉽고 명확하게 풀어서 재작성해야함.
        // API res - overview에 표시
        //       ㄴ - winch/drum리스트 표시
        // 위의 두 케이스가 현재 혼재되어 동작하는 것 같다는 느낌적 느낌 :think:
        const mooredWinch = raw.winches.find(w => w.drums.find(rd => rd.id === d.id));

        const drum: Drum = {
          id: d.id,
          name: d.name,
          position: d.position,
          hasTailLine: d.has_tail_line,
          installedLines: d.installed_lines.map(l => ({
            customId: l.custom_id || null,
            id: l.id,
            isRetired: l.is_retired,
            workingHours: l.working_hours,
            workingHoursLimit: l.working_hours_limit,
            certificateIssuedDate: l.certificate_issued_date,
            createdAt: l.created_at,
            diameter: l.diameter,
            length: l.length,
            lineId: l.line_id,
            mbl: l.mbl,
            meg4Id: l.meg4_id,
            strand: l.strand,
            suppliedDate: l.supplied_date,
          })),
          installations: {
            //@ts-ignore
            main: d.installations.main,
            //@ts-ignore
            tail: d.installations.tail,
          },
          _winchInfo: mooredWinch && {
            id: mooredWinch?.id,
            name: mooredWinch?.name,
            moored: true,
          },
        };
        return drum;
      }),
    }
    : null,
});

//#region WinchDrumTree
export type DrumTreeItem = Drum & {
  /**
   * checked: set null as initial state. If checked is null, this means that the item never been touched.
   */
  checked: null | boolean;
  winchName: string;
  moored: boolean;
};
export type WinchTreeItem = Omit<Winch, 'drums'> & {
  /**
   * checked: set null as initial state. If checked is null, this means that the item never been touched.
   */
  checked: null | boolean;
  drums: {
    [drumId: string]: DrumTreeItem;
  };
  moored: boolean;
};
export type WinchDrumTree = {
  [winchId: string]: WinchTreeItem;
};
export const parseWinchDrumTree = (prevWinchState: WinchDrumTree, vessel: Vessel): WinchDrumTree => {
  const winchDrumTree: WinchDrumTree = {};
  const { activeMooring, winches } = vessel;
  winches.forEach(w => {
    const isWinchMoored = !!activeMooring?.drums.find(md => md._winchInfo?.id === w.id && md._winchInfo?.moored);

    winchDrumTree[w.id] = {
      ...w,
      checked: prevWinchState[w.id] ? prevWinchState[w.id].checked : null,
      drums: {},
      moored: isWinchMoored,
    };
    w.drums.forEach(d => {
      const isDrumMoored = !!activeMooring?.drums.find(md => md.id === d.id && md.moored);
      winchDrumTree[w.id].drums[d.id] = {
        ...d,
        checked: prevWinchState[w.id]?.drums?.[d.id] ? prevWinchState[w.id].drums[d.id].checked : null,
        winchName: w.name,
        moored: isDrumMoored,
      };
    });
  });
  return winchDrumTree;
};
export const updateSelectAllWinchDrums = (winchDrumTree: WinchDrumTree, nextState: boolean): WinchDrumTree => {
  const newWinchDrumTree: WinchDrumTree = {};
  Object.keys(winchDrumTree).forEach(winchId => {
    newWinchDrumTree[winchId] = {
      ...winchDrumTree[winchId],
      checked: nextState,
      drums: {},
    };
    Object.keys(winchDrumTree[winchId].drums).forEach(drumId => {
      newWinchDrumTree[winchId].drums[drumId] = {
        ...winchDrumTree[winchId].drums[drumId],
        checked: nextState,
      };
    });
  });
  return newWinchDrumTree;
};
//#endregion
