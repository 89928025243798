import { useUserState } from 'atoms/authState';
import HttpStatusCode from 'lib/ajax/statusCodes';
import { parseDateTime } from 'lib/utils';
import { RefObject, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import {
  useAddMessageToThreadApiV1LinetalksThreadIdMessagesMutation,
  useStartLinetalkApiV1LinesLineIdLinetalksMutation,
  useUploadAttachmentsToThreadApiV1LinetalksThreadIdAttachmentsMutation,
} from 'services/linetalk/queries';
import { User } from 'services/user/model';
import { useLinetalkPostsState, useLinetalkThreadInfo } from './atoms';
import { scrollToLatest } from './utils';

type NewMessage = {
  message: string;
  attachments?: FileList;
};

export const useMessageSender = (threadId: string, contentRef: RefObject<HTMLDivElement>) => {
  const postElement = contentRef.current;
  const userInfo = useUserState();
  const [{ isNewLinetalk }, setLinetalkThreadInfo] = useLinetalkThreadInfo();
  const [, updatePosts] = useLinetalkPostsState();

  const createTalkApi = useStartLinetalkApiV1LinesLineIdLinetalksMutation(threadId);
  const addPostApi = useAddMessageToThreadApiV1LinetalksThreadIdMessagesMutation(threadId);
  const uploadAttachmentsApi = useUploadAttachmentsToThreadApiV1LinetalksThreadIdAttachmentsMutation(threadId);

  const form = useForm<NewMessage>();

  const onMessageListUpdated = useCallback(() => {
    if (postElement) {
      scrollToLatest(postElement);
    }
  }, [postElement]);

  const _createNewLineTalk = useCallback(
    async (data: NewMessage) => {
      const res = await createTalkApi.mutateAsync({
        lineId: threadId,
        data: {
          message: data.message,
          include_dsr: false,
        },
      });
      if (res.status === HttpStatusCode.CREATED) {
        setLinetalkThreadInfo(prev => ({ ...prev, isNewLinetalk: false }));
        form.reset();
      }
    },
    [createTalkApi, form, setLinetalkThreadInfo, threadId]
  );
  const _sendMessage = useCallback(
    async ({ message, user }: { message: string; user: User }) => {
      const tempId = 'TEMP_' + Date.now();
      updatePosts(prev => ({
        ...prev,
        list: prev.list.concat({
          id: tempId,
          message,
          attachments: [],
          createdAt: parseDateTime(new Date().toISOString()),
          isMine: true,
          author: {
            id: user.id,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
          },
        }),
        lastMessageId: tempId,
      }));

      onMessageListUpdated();

      await addPostApi.mutateAsync({
        threadId,
        data: { message },
      });
    },
    [addPostApi, onMessageListUpdated, threadId, updatePosts]
  );
  const _sendFile = useCallback(
    async ({ attachments, user }: { attachments: FileList; user: User }) => {
      const tempId = 'TEMP_' + Date.now();
      const files = Array.from(attachments || []);
      updatePosts(prev => ({
        ...prev,
        list: prev.list.concat(
          files.map(f => ({
            id: tempId,
            message: '',
            attachments: [
              {
                id: '',
                url: URL.createObjectURL(f),
                filename: f.name,
              },
            ],
            createdAt: parseDateTime(new Date().toISOString()),
            isMine: true,
            author: {
              id: user.id,
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
            },
          }))
        ),
        lastMessageId: tempId,
      }));
      onMessageListUpdated();

      const attachmentList: File[] = [];

      files.forEach(file => {
        attachmentList.push(file as File);
      });
      await uploadAttachmentsApi.mutateAsync({
        threadId,
        data: {
          attachments: attachmentList,
        },
      });
    },
    [onMessageListUpdated, threadId, updatePosts, uploadAttachmentsApi]
  );

  const onSubmit = useCallback(
    async (data: NewMessage) => {
      if (!userInfo) return; // 웬만해서는 userInfo가 없을 일이 없다. 다만 타입 해결을 위해 작성.

      if (isNewLinetalk) {
        await _createNewLineTalk(data);
      } else {
        const { message, attachments } = data;
        form.reset();

        // send message
        if (message) {
          await _sendMessage({ message, user: userInfo });
        }

        // if there is attachments, upload attachments
        if (attachments?.length) {
          await _sendFile({ attachments, user: userInfo });
        }
      }
    },
    [_createNewLineTalk, _sendFile, _sendMessage, form, userInfo, isNewLinetalk]
  );

  return {
    form,
    onSubmit,
    message: addPostApi,
    attachments: uploadAttachmentsApi,
  };
};
