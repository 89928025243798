import { useMutation, useQuery } from '@tanstack/react-query';
import { http, queryClient } from 'lib/ajax/axios';
import { Vessel as VesselApi } from 'swagger/Vessel';
import {
  ListVesselsApiV1VesselsGetParams,
  VesselCreateWithCrewAndSubEntities,
  VesselMemoUpdate,
  VesselUpdateV2,
} from 'swagger/data-contracts';
import { parseVessel } from './model';

export const vesselApi = new VesselApi(http);

export const useListVesselsApiV1VesselsQuery = (params: ListVesselsApiV1VesselsGetParams) =>
  useQuery({
    queryKey: ['vessels', 'list', params],
    queryFn: async () => {
      const res = await vesselApi.listVesselsApiV1VesselsGet(params);
      return res.data;
    },
  });

export const useAddVesselWithFleetManagerAndSubEntitiesApiV12FleetsUserIdVesselsMutation = () =>
  useMutation({
    mutationFn: async (payload: { userId: string; data: VesselCreateWithCrewAndSubEntities }) => {
      return await vesselApi.addVesselWithFleetManagerAndSubEntitiesApiV12FleetsUserIdVesselsPost(
        payload.userId,
        payload.data
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['vessels'] });
    },
  });

export const useVesselV11ApiV11VesselsVesselIdQuery = (vesselId: string, { enabled }: { enabled: boolean }) =>
  useQuery({
    queryKey: ['vessels', 'detail', vesselId],
    queryFn: async () => {
      const res = await vesselApi.getVesselV11ApiV11VesselsVesselIdGet(vesselId);
      return res.data;
    },
    select: data => parseVessel(data),
    enabled,
  });

// listVesselsByFleetApiV1FleetsUserIdVesselsGet
export const useListVesselsByFleetApiV1FleetsUserIdVesselsQuery = (userId: string, { enabled }: { enabled: boolean }) =>
  useQuery({
    queryKey: ['vessels', 'list', userId],
    queryFn: async () => {
      const res = await vesselApi.listVesselsByFleetApiV1FleetsUserIdVesselsGet({ userId });
      return res.data;
    },
    enabled,
  });

// addVesselWithFleetManagerApiV1FleetsUserIdVesselsPost
export const useAddVesselWithFleetManagerApiV1FleetsUserIdVesselsMutation = () =>
  useMutation({
    mutationFn: async (payload: { userId: string; data: VesselCreateWithCrewAndSubEntities }) => {
      return await vesselApi.addVesselWithFleetManagerApiV1FleetsUserIdVesselsPost(payload.userId, payload.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['vessels'] });
    },
  });

// updateVesselInfoApiV11VesselsVesselIdPatch
export const useUpdateVesselInfoApiV11VesselsVesselIdMutation = () =>
  useMutation({
    mutationFn: async (payload: { vesselId: string; data: VesselUpdateV2 }) => {
      return await vesselApi.updateVesselInfoApiV11VesselsVesselIdPatch(payload.vesselId, payload.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['vessels'] });
    },
  });

//updateVesselMemoApiV1VesselsVesselIdMemosPatch
export const useUpdateVesselMemoApiV1VesselsVesselIdMemosMutation = () =>
  useMutation({
    mutationFn: async (payload: { vesselId: string; data: VesselMemoUpdate }) => {
      return await vesselApi.updateVesselMemoApiV1VesselsVesselIdMemosPatch(payload.vesselId, payload.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['vessels'] });
    },
  });

// listVesselsByUserApiV1UsersSelfVesselsGet
export const useListVesselsByUserApiV1UsersSelfVesselsQuery = () =>
  useQuery({
    queryKey: ['vessels', 'list', 'self'],
    queryFn: async () => {
      const res = await vesselApi.listVesselsByUserApiV1UsersSelfVesselsGet();
      return res.data;
    },
  });
