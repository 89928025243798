import { CheckIcon } from '@radix-ui/react-icons';
import * as SelectPrimitive from '@radix-ui/react-select';
import DropdownArrowDown from 'assets/icons/dropdown_arrow_down.svg';
import { AxiosError } from 'axios';
import { useCallback, useMemo, useState } from 'react';
import ReactSelect, { ActionMeta, CSSObjectWithLabel, SingleValue } from 'react-select';
import { css, styled } from 'theme/stitches.config';
import { Icon } from '../Icon';
import { createBody1Style } from '../Typography';

const StyledTrigger = styled(SelectPrimitive.SelectTrigger, {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: '1px solid $gray700',
  padding: '8px 12px',
  // width: 280,
  minHeight: 40,
  color: '$gray900',
  backgroundColor: '$white',
  outline: 'none',
  zIndex: 10,

  fontSize: '$body2',
  fontFamily: '$medium',
  fontWeight: '$medium',

  img: {
    verticalAlign: 'middle',
  },
  variants: {
    fluid: {
      true: {
        width: '100%',
      },
      false: {
        width: 'inherit',
      },
    },
  },
  defaultVariants: {
    fluid: false,
  },
});

const createLineClampStyle = css({
  color: '$gray900',
  overflow: 'hidden',
  display: '-webkit-box',
  overflowWrap: 'anywhere',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',

  WebkitLineClamp: 1,
});

const StyledContent = styled(SelectPrimitive.Content, {
  zIndex: 10,
  overflow: 'scroll',
  backgroundColor: 'white',
  borderRadius: 6,
  boxShadow: '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
});

const StyledViewport = styled(SelectPrimitive.Viewport, {
  padding: 5,
});

const StyledItem = styled(SelectPrimitive.Item, {
  display: 'flex',
  alignItems: 'center',
  minHeight: 40,
  padding: '0 35px 0 25px',
  position: 'relative',
  userSelect: 'none',
  outline: 'none',

  '&[data-disabled]': {
    pointerEvents: 'none',
  },

  '&:focus': {
    backgroundColor: '$blue100',
  },
});
const StyledLabel = styled(SelectPrimitive.Label, {
  padding: '0 25px',
});

const StyledSeparator = styled(SelectPrimitive.Separator, {
  height: 1,
  margin: 5,
});

const StyledItemIndicator = styled(SelectPrimitive.ItemIndicator, {
  position: 'absolute',
  left: 0,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const scrollButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 25,
  backgroundColor: 'white',
  // color: violet.violet11,
  cursor: 'default',
};

const StyledScrollUpButton = styled(SelectPrimitive.ScrollUpButton, scrollButtonStyles);
const StyledScrollDownButton = styled(SelectPrimitive.ScrollDownButton, scrollButtonStyles);

export const Select = SelectPrimitive.Root;
export const SelectTrigger = StyledTrigger;
export const SelectValue = SelectPrimitive.Value;
export const SelectIcon = SelectPrimitive.Icon;
export const SelectContent = StyledContent;
export const SelectViewport = StyledViewport;
export const SelectGroup = SelectPrimitive.Group;
export const SelectItem = StyledItem;
export const SelectItemText = SelectPrimitive.ItemText;
export const SelectItemIndicator = StyledItemIndicator;
export const SelectLabel = StyledLabel;
export const SelectSeparator = StyledSeparator;
export const SelectScrollUpButton = StyledScrollUpButton;
export const SelectScrollDownButton = StyledScrollDownButton;

export type DropdownItem = { id: string; label: string };
type Props<T extends DropdownItem> = {
  list: T[];
  placeholder?: string;
  defaultValue?: T['id'];
  loading?: boolean;
  error?: AxiosError;
  onChange?: (id: string) => void;
  retry?: () => void;
  className?: string;
  fluid?: boolean;
  lineClamp?: number;
};
export const NULL_VALUE = '-';
export const Dropdown2 = <T extends DropdownItem>({
  list,
  placeholder,
  defaultValue,
  loading,
  error,
  fluid,
  className,
  retry,
  lineClamp,
  onChange,
}: Props<T>) => {
  const initialItem = useMemo(() => defaultValue || undefined, [defaultValue]);
  const initialLoading = useMemo(() => loading && list.length === 0, [loading, list]);

  const [value, setValue] = useState<string | undefined>(initialItem);
  const selectedItemText = useMemo(() => {
    if (value === NULL_VALUE) return placeholder;
    return list.find(item => item.id === value)?.label;
  }, [list, value, placeholder]);

  const handleUpdate = useCallback(
    (id: string) => {
      setValue(id);
      if (onChange) onChange(id);
    },
    [onChange]
  );

  return (
    <div style={{ position: 'relative' }}>
      {initialLoading ? (
        <Icon variant='loading' />
      ) : (
        <Select defaultValue={initialItem} onValueChange={handleUpdate}>
          <SelectTrigger className={className} fluid={fluid}>
            <SelectValue asChild>
              {value ? (
                <span style={{ textAlign: 'left' }} className={lineClamp ? createLineClampStyle() : ''}>
                  {selectedItemText}
                </span>
              ) : (
                <span style={{ textAlign: 'left' }} className={lineClamp ? createLineClampStyle() : ''}>
                  {placeholder}
                </span>
              )}
            </SelectValue>
            <SelectIcon>
              <img src={DropdownArrowDown} alt='' />
            </SelectIcon>
          </SelectTrigger>
          <SelectContent>
            <SelectScrollUpButton>
              <img src={DropdownArrowDown} alt='' style={{ transform: 'rotate(180deg)' }} />
            </SelectScrollUpButton>
            <SelectViewport>
              <SelectGroup>
                {loading ? (
                  <>loading..</>
                ) : list.length === 0 ? (
                  <>No result</>
                ) : error ? (
                  <button onClick={retry}>Retry</button>
                ) : (
                  list.map(({ id, label }) => (
                    <SelectItem value={id} className={createBody1Style({ weight: 'medium' })} key={id}>
                      <SelectItemText>{label}</SelectItemText>
                      <SelectItemIndicator>
                        <CheckIcon />
                      </SelectItemIndicator>
                    </SelectItem>
                  ))
                )}
              </SelectGroup>
            </SelectViewport>
            <SelectScrollDownButton>
              <img src={DropdownArrowDown} alt='' />
            </SelectScrollDownButton>
          </SelectContent>
        </Select>
      )}
    </div>
  );
};

const DropdownBox = styled('div', {
  fontSize: '$body1',
  fontFamily: '$medium',
  fontWeight: '$medium',
  variants: {
    isDisabled: {
      true: {
        opacity: 0.5,
      },
    },
  },
});
//34, 120, 254
const customStyles = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    border: '1px solid #808080',
    borderRadius: 0,
    height: 40,
    color: 'black',
    backgroundColor: 'white',
  }),
};

type Item = { value: string; label: string };
type DropdownProps<T> = {
  list?: T[];
  value?: SingleValue<T>;
  isDisabled?: boolean;
  onChange?: (newValue: SingleValue<T>, actionMeta: ActionMeta<T>) => void;
};
export const Dropdown = <T extends DropdownItem>({ list, value, onChange, isDisabled }: DropdownProps<T>) => {
  
  return (
    <DropdownBox isDisabled={isDisabled}>
      <ReactSelect isSearchable={false} options={list} value={value} styles={customStyles} onChange={onChange} isDisabled={isDisabled} />
    </DropdownBox>
  );
};
