import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { http, queryClient } from 'lib/ajax/axios';
import { atom, useRecoilState } from 'recoil';
import {
  BodyUploadAttachmentsToThreadApiV1LinetalksThreadIdAttachmentsPost,
  LineTalkPostCreate,
  LineTalkThreadCreate,
  LineTalkThreadList,
  ListLinetalkPostsApiV1LinetalksThreadIdMessagesGetParams,
  ListLinetalkThreadsApiV1LinetalksGetParams,
  ListLinetalkThreadsByVesselApiV1VesselsVesselIdLinetalksGetParams
} from 'swagger/data-contracts';
import { LineTalk } from 'swagger/LineTalk';
import { parseLineTalkPost, parseLineTalkThread } from './model';

const linetalkApi = new LineTalk(http);

export const useListLinetalksApiV1LinetalksQuery = <TData>(
  request: ListLinetalkThreadsApiV1LinetalksGetParams,
  options?: Omit<UseQueryOptions<LineTalkThreadList, AxiosError, TData>, 'queryKey' | 'queryFn'>
) => {
  return useQuery<LineTalkThreadList, AxiosError, TData>({
    queryKey: ['linetalks', 'list', request],
    queryFn: async () => {
      const { data } = await linetalkApi.listLinetalkThreadsApiV1LinetalksGet(request);
      return data;
    },
    ...options,
  });
};
// listLinetalkThreadsByVesselApiV1VesselsVesselIdLinetalksGet
export const useListLinetalksByVesselApiV1VesselsVesselIdLinetalksQuery = <TData>(
  request: ListLinetalkThreadsByVesselApiV1VesselsVesselIdLinetalksGetParams,
  options?: Omit<UseQueryOptions<LineTalkThreadList, AxiosError, TData>, 'queryKey' | 'queryFn'>
) => {
  return useQuery<LineTalkThreadList, AxiosError, TData>({
    queryKey: ['linetalks', 'list', request],
    queryFn: async () => {
      const res = await linetalkApi.listLinetalkThreadsByVesselApiV1VesselsVesselIdLinetalksGet(request);
      return res.data;
    },
    ...options,
  });
};
export const useGetLinetalkThreadApiV1LinetalksThreadIdQuery = (threadId: string) => {
  return useQuery({
    queryKey: ['linetalks', threadId],
    queryFn: async () => {
      const res = await linetalkApi.getLinetalkThreadApiV1LinetalksThreadIdGet(threadId);
      return res.data;
    },
    select: parseLineTalkThread,
  });
};

export const useInviteDsrStaffToThreadApiV1LinetalksThreadIdInvitesMutation = (threadId: string) => {
  return useMutation({
    mutationFn: async (threadId: string) => {
      return await linetalkApi.inviteDsrStaffToThreadApiV1LinetalksThreadIdInvitesPost(threadId);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['linetalks', threadId] });
    },

  });
};

const shouldFetchAtom = atom<boolean>({
  key: 'shouldFetchAtom',
  default: true,
});
//startLinetalkApiV1LinesLineIdLinetalksPost
export const useListLinetalkPostsApiV1LinetalksThreadIdMessagesQuery = (
  request: ListLinetalkPostsApiV1LinetalksThreadIdMessagesGetParams
) => {
  const [shouldFetch, setShouldFetch] = useRecoilState(shouldFetchAtom);
  const query = useQuery({
    queryKey: ['linetalks', request.threadId, 'messages'],
    queryFn: async () => {
      const res = await linetalkApi.listLinetalkPostsApiV1LinetalksThreadIdMessagesGet(request)
      if (res instanceof AxiosError) {
        if (res.response?.status === 404) {
          setShouldFetch(false);
        }
      }
      return res.data;
    },
    select: data => {
      const loadedPost = data.posts.map(parseLineTalkPost).reverse();
      const totalCount = data.total_count;
      const isOlderPost = data.offset > 0;

      return {
        totalCount,
        loadedPost,
        isOlderPost,
        offset: data.offset,
      }
    },
    refetchInterval: 3000,
    enabled: shouldFetch,
  });

  return query;
};

export const useStartLinetalkApiV1LinesLineIdLinetalksMutation = (threadId: string) => {
  const [, setShouldFetch] = useRecoilState(shouldFetchAtom);
  return useMutation({
    mutationFn: (payload: { lineId: string; data: LineTalkThreadCreate }) => {
      return linetalkApi.startLinetalkApiV1LinesLineIdLinetalksPost(payload.lineId, payload.data);
    },
    onSuccess: async () => {
      setShouldFetch(true);
      await queryClient.fetchQuery({
        queryKey: ['linetalks', threadId, 'messages'],
        queryFn: () => {
          return linetalkApi.listLinetalkPostsApiV1LinetalksThreadIdMessagesGet({
            threadId,
            offset: 0,
            limit: 10,
          });
        }
      })
      await queryClient.invalidateQueries({ queryKey: ['linetalks'] });
    },
  });
};

// addMessageToThreadApiV1LinetalksThreadIdMessagesPost
export const useAddMessageToThreadApiV1LinetalksThreadIdMessagesMutation = (threadId: string) => {
  return useMutation({
    mutationFn: (payload: { threadId: string; data: LineTalkPostCreate }) => {
      return linetalkApi.addMessageToThreadApiV1LinetalksThreadIdMessagesPost(payload.threadId, payload.data);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['linetalks', threadId, 'messages'] });
    },
  });
};

//uploadAttachmentsToThreadApiV1LinetalksThreadIdAttachmentsPost
export const useUploadAttachmentsToThreadApiV1LinetalksThreadIdAttachmentsMutation = (threadId: string) => {
  return useMutation({
    mutationFn: (payload: {
      threadId: string;
      data: BodyUploadAttachmentsToThreadApiV1LinetalksThreadIdAttachmentsPost;
    }) => {
      return linetalkApi.uploadAttachmentsToThreadApiV1LinetalksThreadIdAttachmentsPost(
        payload.threadId,
        payload.data
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['linetalks', threadId, 'messages'] });
    },
  });
};
