import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import recoilInitializer from 'atoms/recoilInitializer';
import { queryClient } from 'lib/ajax/axios';
import { DebugObserver } from 'lib/requestLogger';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import App from './App';

const IS_DEV_ENV = process.env.NODE_ENV === 'development';

if (IS_DEV_ENV && process.env.REACT_APP_MSW_SERVER === 'true') {
  const { worker } = require('./mocks/browser');
  worker.start({
    onUnhandledRequest: 'bypass',
  });
}
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <RecoilRoot initializeState={recoilInitializer}>
      <QueryClientProvider client={queryClient}>
        <>
          {IS_DEV_ENV && <DebugObserver />}
          <App />
        </>
        {IS_DEV_ENV && <ReactQueryDevtools initialIsOpen />}
      </QueryClientProvider>
    </RecoilRoot>
  </React.StrictMode>
);
