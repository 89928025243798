/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddMessageToThreadApiV1LinetalksThreadIdMessagesPostData,
  AddMessageToThreadApiV1LinetalksThreadIdMessagesPostError,
  BodyUploadAttachmentsToThreadApiV1LinetalksThreadIdAttachmentsPost,
  GetLinetalkThreadApiV1LinetalksThreadIdGetData,
  GetLinetalkThreadApiV1LinetalksThreadIdGetError,
  InviteDsrStaffToThreadApiV1LinetalksThreadIdInvitesPostData,
  InviteDsrStaffToThreadApiV1LinetalksThreadIdInvitesPostError,
  LineTalkPostCreate,
  LineTalkThreadCreate,
  ListLinetalkPostsApiV1LinetalksThreadIdMessagesGetData,
  ListLinetalkPostsApiV1LinetalksThreadIdMessagesGetError,
  ListLinetalkPostsApiV1LinetalksThreadIdMessagesGetParams,
  ListLinetalkThreadsApiV1LinetalksGetData,
  ListLinetalkThreadsApiV1LinetalksGetError,
  ListLinetalkThreadsApiV1LinetalksGetParams,
  ListLinetalkThreadsByVesselApiV1VesselsVesselIdLinetalksGetData,
  ListLinetalkThreadsByVesselApiV1VesselsVesselIdLinetalksGetError,
  ListLinetalkThreadsByVesselApiV1VesselsVesselIdLinetalksGetParams,
  StartLinetalkApiV1LinesLineIdLinetalksPostData,
  StartLinetalkApiV1LinesLineIdLinetalksPostError,
  UploadAttachmentsToThreadApiV1LinetalksThreadIdAttachmentsPostData,
  UploadAttachmentsToThreadApiV1LinetalksThreadIdAttachmentsPostError,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class LineTalk<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
undefined
 *
undefined
 */
  listLinetalkThreadsByVesselApiV1VesselsVesselIdLinetalksGet = (
    { vesselId, ...query }: ListLinetalkThreadsByVesselApiV1VesselsVesselIdLinetalksGetParams,
    params: RequestParams = {}
  ) =>
    this.http.request<
      ListLinetalkThreadsByVesselApiV1VesselsVesselIdLinetalksGetData,
      ListLinetalkThreadsByVesselApiV1VesselsVesselIdLinetalksGetError
    >({
      path: `/api/v1/vessels/${vesselId}/linetalks/`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  listLinetalkThreadsApiV1LinetalksGet = (
    query: ListLinetalkThreadsApiV1LinetalksGetParams,
    params: RequestParams = {}
  ) =>
    this.http.request<ListLinetalkThreadsApiV1LinetalksGetData, ListLinetalkThreadsApiV1LinetalksGetError>({
      path: `/api/v1/linetalks/`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  getLinetalkThreadApiV1LinetalksThreadIdGet = (threadId: string, params: RequestParams = {}) =>
    this.http.request<GetLinetalkThreadApiV1LinetalksThreadIdGetData, GetLinetalkThreadApiV1LinetalksThreadIdGetError>({
      path: `/api/v1/linetalks/${threadId}/`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  inviteDsrStaffToThreadApiV1LinetalksThreadIdInvitesPost = (threadId: string, params: RequestParams = {}) =>
    this.http.request<
      InviteDsrStaffToThreadApiV1LinetalksThreadIdInvitesPostData,
      InviteDsrStaffToThreadApiV1LinetalksThreadIdInvitesPostError
    >({
      path: `/api/v1/linetalks/${threadId}/invites/`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  startLinetalkApiV1LinesLineIdLinetalksPost = (
    lineId: string,
    data: LineTalkThreadCreate,
    params: RequestParams = {}
  ) =>
    this.http.request<StartLinetalkApiV1LinesLineIdLinetalksPostData, StartLinetalkApiV1LinesLineIdLinetalksPostError>({
      path: `/api/v1/lines/${lineId}/linetalks/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  listLinetalkPostsApiV1LinetalksThreadIdMessagesGet = (
    { threadId, ...query }: ListLinetalkPostsApiV1LinetalksThreadIdMessagesGetParams,
    params: RequestParams = {}
  ) =>
    this.http.request<
      ListLinetalkPostsApiV1LinetalksThreadIdMessagesGetData,
      ListLinetalkPostsApiV1LinetalksThreadIdMessagesGetError
    >({
      path: `/api/v1/linetalks/${threadId}/messages/`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  addMessageToThreadApiV1LinetalksThreadIdMessagesPost = (
    threadId: string,
    data: LineTalkPostCreate,
    params: RequestParams = {}
  ) =>
    this.http.request<
      AddMessageToThreadApiV1LinetalksThreadIdMessagesPostData,
      AddMessageToThreadApiV1LinetalksThreadIdMessagesPostError
    >({
      path: `/api/v1/linetalks/${threadId}/messages/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  uploadAttachmentsToThreadApiV1LinetalksThreadIdAttachmentsPost = (
    threadId: string,
    data: BodyUploadAttachmentsToThreadApiV1LinetalksThreadIdAttachmentsPost,
    params: RequestParams = {}
  ) =>
    this.http.request<
      UploadAttachmentsToThreadApiV1LinetalksThreadIdAttachmentsPostData,
      UploadAttachmentsToThreadApiV1LinetalksThreadIdAttachmentsPostError
    >({
      path: `/api/v1/linetalks/${threadId}/attachments/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });
}
