import { winsAdminAxios } from 'lib/ajax/axios';
import { uniqBy } from 'lodash';
import { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useListLinetalkPostsApiV1LinetalksThreadIdMessagesQuery } from 'services/linetalk/queries';
import { useClearLinetalkPostsState, useLinetalkPostsState } from './atoms';
import { scrollToLatest } from './utils';

export const useMessageController = (threadId: string, contentRef: RefObject<HTMLDivElement>) => {
  const postElement = contentRef.current;
  const [initialLoading, setInitialLoading] = useState(true);

  const onMessageListUpdated = useCallback(() => {
    if (postElement) {
      scrollToLatest(postElement);
    }
  }, [postElement]);

  const posts = useMessageList(threadId, onMessageListUpdated);

  useEffect(() => {
    if (posts.status === 'success') {
      setInitialLoading(false);
    }
  }, [posts.status]);

  const clear = useClearLinetalkPostsState();

  useEffect(() => { 
    return () => clear();
  }, []);

  return {
    ...posts,
    initialLoading,
  };
};

const useMessageList = (threadId: string, onUpdated: () => void) => {
  const [offset, setOffset] = useState(0);
  const postsApi = useListLinetalkPostsApiV1LinetalksThreadIdMessagesQuery({
    threadId,
    offset,
    limit: 10,
  });

  const [posts, updatePosts] = useLinetalkPostsState();
  const [inLoadMore, setInLoadMore] = useState(false);

  const handlers = useMemo(
    () => ({
      onLoadMore: async () => {
        setInLoadMore(true);
        const newOffset = posts.offset + posts.limit;
        setOffset(newOffset);
        setInLoadMore(false);
      },
      onDownload: async (fileSource: string, filename: string) => {
        // not used
        // TODO: CORS 에러 해결 필요
        const res = await winsAdminAxios.get(fileSource, {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
          responseType: 'blob',
        });

        const a = document.createElement('a');
        const url = window.URL.createObjectURL(res.data);
        a.href = url;
        a.setAttribute('download', filename);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
    }),
    [posts.limit, posts.offset]
  );

  useEffect(() => {
    if (postsApi.status === 'success') {
      const { isOlderPost, loadedPost, totalCount, offset } = postsApi.data;

      updatePosts(prev => {
        const updatedList = isOlderPost
          ? [...loadedPost, ...prev.list]
          : uniqBy(
              [...prev.list, ...loadedPost].filter(m => !m.id.includes('TEMP')),
              'id'
            );

        const hasNext = totalCount > updatedList.length;
        const updatedInfo = {
          ...prev,
          list: updatedList,
          hasNext,
          totalCount,
          offset,
        };
        if (isOlderPost) {
          updatedInfo.firstMessageId = updatedList[0].id;
        } else {
          updatedInfo.lastMessageId = updatedList[updatedList.length - 1].id;
        }
        return updatedInfo;
      });

      if (!isOlderPost) onUpdated();
    }
  }, [threadId, postsApi.status, postsApi.data, updatePosts, onUpdated]);

  return {
    ...postsApi,
    data: posts,
    inLoadMore,
    handlers,
  };
};
